import React, { useEffect } from "react";

const HowtoDelete = () => {
  useEffect(() => {
    // Scroll to the top of the website when the component is rendered
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="delivery-information" className="container-fluid link-content">
      <div className="link-content-titles">How to Delete your Account</div>
      <br></br>
      <h2 className="c11" id="h.f821n7hj0fog">
        <span className="c4">
          1) Access your Account Tab within the AI Alpha App
        </span>
      </h2>
      <br></br>
      <p className="c1">
        <span className="c3">
          Once you are logged into the app, you'll have the opportunity to
          delete your account whenever you desire to, regardless of the method
          you used to create your account.<br></br>
          <br></br>
          The first step is to access your Account Tab, which is on the main
          bottom menu of the app.
          <br></br>
        </span>
      </p>
      <br></br>
      <h2 className="c11" id="h.f821n7hj0fog">
        <span className="c4">
          2) Go to Settings and then select "Delete Account"
        </span>
      </h2>
      <br></br>
      <p className="c1">
        <span className="c3">
          Once you get to this section, a new screen will appear, which will ask
          you for confirmation.
          <br></br>
          If you created your account with email-password, you will be asked to
          enter your password to confirm the deletion. However, if you used a
          different method to create your account (like the Google Sign-In), you
          will be asked to confirm the deletion by clicking on the "Delete
          Account" button, without having to enter any additional data.
          <br></br>
        </span>
      </p>
      <h2 className="c11" id="h.f821n7hj0fog">
        <span className="c4">3) Finish the process</span>
      </h2>
      <br></br>
      <p className="c1">
        <span className="c3">
          After the last step above, your account will be deleted, as well as
          the information associated with it. You will be logged out of the app
          and will have to create a new account if you wish to use the app
          again.
          <br></br>
        </span>
      </p>
    </div>
  );
};
export default HowtoDelete;
