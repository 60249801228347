import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import "./css/main.css";
import "./css/footerSections.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar } from "react-bootstrap";

import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./components/FooterSections/PrivacyPolicy";
import Eula from "./components/FooterSections/Eula";
import TermsAndConditions from "./components/FooterSections/TermsAndConditions";
import Educate from "./components/Educate/Educate";
import Knowledge from "./components/Knowledge/Knowledge";
import Insights from "./components/Insights/Insights";
import Decode from "./components/Decode/Decode";
import Simplicity from "./components/Simplicity/Simplicity";
import Empower from "./components/Empower/Empower";
import Content from "./components/Content/Content";
import NavigationIndicator from "./components/NavigationIndicator";
import HowtoDelete from "./components/HowtoDelete/HowtoDelete";

function App() {
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const routes = [
    "/",
    "/knowledge",
    "/insights",
    "/decode",
    "/simplicity",
    "/empower",
    "/content",
    "/privacypolicy",
    "/termsandconditions",
    "/eula",
    "/howtodelete",
  ];

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [isSlidingActive, setIsSlidingActive] = useState(false);
  const rootRef = useRef(null);
  useEffect(() => {
    // Scroll to the top of the website whenever the component changes
    rootRef.current.scrollTop = 0;
  }, [window.location.pathname]);
  const handleTitleColorChange = (index) => {
    const navbarTitles = document.querySelectorAll(".navbar-brand-custom");
    navbarTitles.forEach((title, i) => {
      if (i === index) {
        title.classList.add("active");
      } else {
        title.classList.remove("active");
      }
    });
  };

  const handleItemClick = (index) => {
    setActiveItem(index);
    navigate(routes[index]);
    handleTitleColorChange(index);
  };

  const handleDotClick = (index) => {
    setActiveItem(index);
    navigate(routes[index]);
    handleTitleColorChange(index);
  };

  const navigateTo = (index) => {
    setActiveItem(index);
    navigate(routes[index]);
    handleTitleColorChange(index);
  };

  const handlePrev = () => {
    const newIndex = activeItem === 0 ? 6 : activeItem - 1;
    navigateTo(newIndex);
  };

  const handleNext = () => {
    const newIndex = activeItem === 6 ? 0 : activeItem + 1;
    navigateTo(newIndex);
  };

  useEffect(() => {
    // Set initial active item based on the route
    const initialIndex = routes.findIndex(
      (route) => route === window.location.pathname
    );
    setActiveItem(initialIndex !== -1 ? initialIndex : 0);
  }, []); // Empty dependency array

  // Render the component dynamically based on activeItem
  const renderActiveComponent = () => {
    if (window.location.pathname === "/privacypolicy") {
      return <PrivacyPolicy />;
    } else if (window.location.pathname === "/eula") {
      return <Eula />;
    } else if (window.location.pathname === "/termsandconditions") {
      return <TermsAndConditions />;
    } else if (window.location.pathname === "/howtodelete") {
      return <HowtoDelete />;
    } else {
      switch (activeItem) {
        case 0:
          return <Educate />;
        case 1:
          return <Knowledge />;
        case 2:
          return <Insights />;
        case 3:
          return <Decode />;
        case 4:
          return <Simplicity />;
        case 5:
          return <Empower />;
        case 6:
          return <Content />;
        default:
          return null;
      }
    }
  };

  return (
    <div
      ref={rootRef}
      className={`content-container ${isSlidingActive ? "sliding" : ""}`}
    >
      <Navbar
        bg="transparent"
        expand="lg"
        className="justify-content-center navbar-custom"
      >
        <Link
          to="/"
          className={`navbar-brand-custom ${activeItem === 0 ? "active" : ""}`}
          onClick={() => handleItemClick(0)}
        >
          Educate
        </Link>
        <Link
          to="/knowledge"
          className={`navbar-brand-custom ${activeItem === 1 ? "active" : ""}`}
          onClick={() => handleItemClick(1)}
        >
          Knowledge
        </Link>
        <Link
          to="/insights"
          className={`navbar-brand-custom ${activeItem === 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
        >
          Insights
        </Link>
        <Link
          to="/decode"
          className={`navbar-brand-custom ${activeItem === 3 ? "active" : ""}`}
          onClick={() => handleItemClick(3)}
        >
          Decode
        </Link>
        <Link
          to="/simplicity"
          className={`navbar-brand-custom ${activeItem === 4 ? "active" : ""}`}
          onClick={() => handleItemClick(4)}
        >
          Simplicity
        </Link>
        <Link
          to="/empower"
          className={`navbar-brand-custom ${activeItem === 5 ? "active" : ""}`}
          onClick={() => handleItemClick(5)}
        >
          Empower
        </Link>
        <Link
          to="/content"
          className={`navbar-brand-custom ${activeItem === 6 ? "active" : ""}`}
          onClick={() => handleItemClick(6)}
        >
          Content
        </Link>
      </Navbar>
      {renderActiveComponent()}
      <NavigationIndicator
        activeIndex={activeItem}
        handleDotClick={handleDotClick}
      />

      <Footer />
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
